<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="170"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>
    <div class="text-center mt-3 ml-2 mr-2">
      <v-img
        :src="logoUrl"
      />
    </div>
    <v-list
      dense
      nav
    />

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <template v-if="permissions.includes('di_admin')">
      <div class="ml-3">
        <v-btn
          color="secondary"
          class="white--text"
          min-width="125"
          @click="switchApp()"
        >
          Switch App
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import ChatovateService from '@/services/ChatovateService.js'
  // Utilities
  import {
    mapState, mapGetters,
  } from 'vuex'
  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      items: [],
      app: null,
      logoUrl: null,
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      ...mapGetters(['permissions', 'dealerGroupId', 'di_admin', 'appName', 'apptsSet', 'apptsLost', 'accountId']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          group: '',
          title: this.$t('avatar'),
          children: [
            {
              href: '',
              title: this.$t('my-profile'),
            },
            {
              to: '',
              title: this.$t('edit-profile'),
            },
            {
              to: '',
              title: this.$t('settings'),
            },
          ],
        }
      },
    },
    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
      appName () {
        this.filterMenu()
        this.setLogoUrl()
      },
      apptsSet () {
        // Redraw menu to update the badge on appts set.
        this.filterMenu()
      },
      apptsLost () {
        // Redraw menu to update the badge on lost set.
        this.filterMenu()
      },
      accountId () {
        // Redraw menu to update the badge on appts set.
        this.filterMenu()
      },
    },
    created () {
      this.$store.dispatch('set_appts_set', null)
      this.$store.dispatch('set_appts_lost', null)
      var host = window.location.host
      this.app = 'insights'
      if (new RegExp('dealerinsights').test(host)) {
        this.app = 'insights'
        this.$vuetify.theme.themes.light.primary = this.$vuetify.theme.themes.primary
        this.$vuetify.theme.themes.light.secondary = this.$vuetify.theme.themes.secondary
      }
      if (new RegExp('attemptingcontact').test(host)) {
        this.app = 'contact'
        this.$vuetify.theme.themes.light.primary = '#90C74C'
        this.$vuetify.theme.themes.light.secondary = '#FF4081'
      }
      if (new RegExp('localhost').test(host)) {
        this.app = 'insights'
        this.$vuetify.theme.themes.light.primary = '#90C74C'
        this.$vuetify.theme.themes.light.secondary = '#FF4081'
      }
      this.$store.dispatch('set_appName', this.app)
      this.filterMenu()
      this.setLogoUrl()
    },
    methods: {
      setLogoUrl () {
        if (this.appName === 'insights') {
          this.logoUrl = 'https://app.dealerinsights.com/assets/images/di_logo.png'
        }
        if (this.appName === 'contact') {
          this.logoUrl = 'https://s3.amazonaws.com/s3.dealerinsights.net/ac_logo.png'
        }
        if (!this.appName) {
          this.logoUrl = 'https://app.dealerinsights.com/assets/images/di_logo.png'
        }
      },
      filterMenu () {
        ChatovateService.getAccountStats()
          .then(response => {
            this.$store.dispatch('set_appts_set', parseInt(response.data.appts_set))
            this.$store.dispatch('set_appts_lost', parseInt(response.data.appts_lost))
          })
          .catch(error => {
            console.log('Error loading account stats')
          })
        this.items = this.allMenuItems().filter(i => i.app === this.appName)
        this.items = this.items.filter(i => i.permissions.some(p => this.permissions.includes(p)))

        var element = {
          to: '/dar_status?dgid=' + this.dealerGroupId,
          title: 'DAR Admin',
        }
        if (this.permissions.includes('dar_admin') && this.appName === 'insights') {
          this.items.push(element)
        }

        var element = {
          to: '/imos_campaigns',
          title: 'iMOS Campaigns',
        }
        if (this.permissions.includes('imos') && this.appName === 'insights') {
          this.items.push(element)
        }

        
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      switchApp () {
        if (this.appName === 'insights') {
          this.$store.dispatch('set_appName', 'contact')
        } else {
          this.$store.dispatch('set_appName', 'insights')
        }
      },
      allMenuItems () {
        return [
          {
            title: 'Home',
            to: '/home',
            icon: 'mdi-home',
            permissions: ['insights'],
            app: 'insights',
          },
          {
            group: '/dms_sales',
            // icon: 'mdi-car',
            title: 'DMS Sales',
            permissions: ['insights', 'chatovate'],
            app: 'insights',
            children: [
              {
                title: 'Sale Records',
                to: 'dms_sales_search',
              },
              {
                title: 'Matchbacks',
                to: 'sales_matchbacks',
              },
              {
                title: 'Sales with No Recent Service',
                to: 'purchased_no_serviced_records',
              },
            ],
          },
          {
            group: '/dms_service',
            // icon: 'mdi-wrench',
            title: 'DMS Service',
            permissions: ['insights', 'chatovate'],
            app: 'insights',
            children: [
              {
                title: 'Service Records',
                to: 'service_records',
              },
              {
                title: 'Not Recently Serviced',
                to: 'not_recently_serviced',
              },
            ],
          },
          {
            group: '/vehicles',
            // icon: 'mdi-car-sports',
            title: 'Vehicles',
            permissions: ['insights'],
            app: 'insights',
            children: [
              {
                title: 'Vehicles',
                to: 'in_stock',
              },
              {
                title: 'Vehicle Groups',
                to: 'vehicle_groups',
              },
            ],
          },
          {
            to: '/campaigns',
            // icon: 'mdi-card-account-mail-outline',
            title: 'Campaigns',
            permissions: ['insights'],
            app: 'insights',
          },
          {
            to: '/vendors',
            // icon: 'mdi-newspaper-variant-outline',
            title: 'Vendors',
            permissions: ['insights'],
            app: 'insights',
          },
          {
            to: '/gmb',
            title: 'Google My Business',
            permissions: ['insights'],
            app: 'insights',
          },  
          {
            to: '/mailers',
            title: 'Mailers',
            permissions: ['insights'],
            app: 'insights',
          },                 
          {
            to: '/upload_files',
            // icon: 'mdi-text-box-plus',
            title: 'Upload Files',
            permissions: ['insights'],
            app: 'insights',
          },
          {
            to: '/attempting_contact/missions',
            title: 'Home',
            permissions: ['chatovate'],
            app: 'contact',
          },
          {
            to: '/attempting_contact/recent_customer_messages',
            title: 'Recent Messages',
            permissions: ['chatovate'],
            app: 'contact',
          },          
          {
            to: '/attempting_contact/appointments',
            title: 'Appointments',
            permissions: ['chatovate'],
            app: 'contact',
            badgeColor: this.$vuetify.theme.themes.light.secondary,
            badgeContent: this.apptsSet,
          },
          {
            to: '/attempting_contact/lost',
            title: 'Lost',
            permissions: ['chatovate'],
            app: 'contact',
            badgeColor: this.$vuetify.theme.themes.light.secondary,
            badgeContent: this.apptsLost,
          },
          {
            to: '/admin/account_statuses',
            title: 'DI Admin',
            permissions: ['di_admin'],
            app: 'insights',
          },
          {
            to: '/open_ai',
            title: 'Open AI',
            permissions: ['open_ai'],
            app: 'insights',
          },
          {
            to: '/admin/manage_dnc',
            title: 'Manage DNC',
            permissions: ['di_admin'],
            app: 'contact',
          },          
        ]
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-item__icon
      min-width: 1px
    .v-list-item__title, .v-list-item__subtitle
      white-space: normal
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 1px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
